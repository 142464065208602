import React, {useEffect, useState} from 'react';
import SkeletonLoader from '../SkeletonLoader';
import {Container, Row} from "react-bootstrap";
import {AppDispatch} from "../../utils/store";
import {useDispatch} from "react-redux";
import Package from "../../models/Package";
import {Typography} from "@mui/material";
import PackageComponent from '../Package';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BottomBorder from "../BottomBorder";
import {styled} from "@mui/system";
import {useApiService} from "../../context/ApiServiceContext";
import {ArrowLeft, ArrowRight} from "../../views/arrow";

const StyledSlider = styled(Slider)(({theme}) => ({
    '& .slick-list': {
        '@media screen and (min-width: 768px)': {
            padding: '10px 0 20px 8px',
            textAlign: 'right',
        },
    },
    '& .slick-track': {
        '@media screen and (min-width: 768px)': {
            marginLeft: '-10px',
            marginRight: '-10px',
        },
    },
    '& .slick-next, & .slick-prev': {
        width: 'auto'
    },
}));


const PackageList: React.FC = () => {
    const apiService = useApiService();
    const dispatch: AppDispatch = useDispatch();
    const [packages, setPackages] = useState<Package[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        apiService.api.get<Package[]>('/package/list')
            .then(response => {
                setPackages(response.data.sort((a,b) => a.linksCount - b.linksCount));
                setIsLoading(false);
            });
    }, []);


    const settings = {
        dots: false,
        initialSlide: 1,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 4, // show up to 4 items
        slidesToScroll: 4, // scroll 4 items at a time
        arrows: packages.length > 4,
        swipe: true,
        rtl: true,
        nextArrow: <ArrowRight/>,
        prevArrow: <ArrowLeft/>,
        responsive: [
            {
                breakpoint: 1400, // md breakpoint
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                },
            },
            {
                breakpoint: 1024, // md breakpoint
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                },
            },
            {
                breakpoint: 768, // xs breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // arrows: false,
                },
            },
            // You can add more breakpoints as needed
        ],
    };

    return (
        <>
            <Container className="pb-5">
                <Row>
                    <Typography variant="h3">חבילות קישורים</Typography>
                    <BottomBorder width={69}></BottomBorder>
                </Row>
                <Row style={{marginTop: "42px"}} className="px-4 px-sm-0">
                    {isLoading ? (
                        <SkeletonLoader amount={2} marginTop={false}/>
                    ) : (
                        <StyledSlider {...settings}>
                            {packages.map((pack, index) => (
                                <div key={index} dir='rtl'>
                                    <PackageComponent {...pack}/>
                                </div>
                            ))}
                        </StyledSlider>
                    )}
                </Row>
            </Container>
        </>
    );
};

export default PackageList;