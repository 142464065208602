import React from 'react';
import {Route, Routes} from 'react-router-dom';
import AdminSidebar from "../components/admin/AdminSidebar";
import AdminCategories from "../components/admin/AdminCategories";
import AdminPackages from "../components/admin/AdminPackages";
import AdminLinks from "../components/admin/AdminLinks";
import AdminUsers from "../components/admin/AdminUsers";
import {Col, Container, Row} from "react-bootstrap";
import AdminOrders from "../components/admin/AdminOrders";
import AdminRecommendedLinks from "../components/admin/AdminRecommended";
import AdminTestimonials from "../components/admin/AdminTestimonials";
import AdminVideos from "../components/admin/AdminVideos";
import AdminPromos from "../components/admin/promo/AdminPromos";
import AdminPromoDetails from "../components/admin/promo/AdminPromoDetails";
import AdminLinksImport from "../components/admin/AdminImportLinksCsv";
import AdminImportLinkThumbnails from "../components/admin/AdminImportLinkThumbnails";
import AdminLegal from "../components/admin/AdminLegal";
import AdminDemoArticles from "../components/admin/AdminDemoArticles";
import AdminRecommendations from "../components/admin/AdminRecommendations";
import AdminLegalDocuments from "../components/admin/AdminLegalDocument";
import AdminDynamicPages from "../components/admin/AdminDynamicPage";
import AdminFAQ from "../components/admin/AdminFAQ";

const AdminRouter: React.FC = () => {
    return (
        <Container fluid className="vh-100">
            <Row className="h-100">
                <Col md={2} className="ps-0">
                    <AdminSidebar />
                </Col>
                <Col md={10}>
                    <Routes>
                        <Route path="categories" element={<AdminCategories />} />
                        <Route path="links" element={<AdminLinks />} />
                        <Route path="links-bulk-update" element={<AdminLinksImport />} />
                        <Route path="links-thumbnail-bulk-update" element={<AdminImportLinkThumbnails />} />
                        <Route path="recommended" element={<AdminRecommendedLinks />} />
                        <Route path="packages" element={<AdminPackages />} />
                        <Route path="users" element={<AdminUsers />} />
                        <Route path="orders" element={<AdminOrders />} />
                        <Route path="testimonials" element={<AdminTestimonials />} />
                        <Route path="faq" element={<AdminFAQ />} />
                        <Route path="videos" element={<AdminVideos />} />
                        <Route path="promos" element={<AdminPromos />} />
                        <Route path="promo/:promoId?" element={<AdminPromoDetails />} />
                        <Route path="legal" element={<AdminLegalDocuments />} />
                        <Route path="dynamic-pages" element={<AdminDynamicPages />} />
                        <Route path="demo-articles" element={<AdminDemoArticles />} />
                        <Route path="affiliation" element={<AdminRecommendations />} />
                        <Route path="/*" element={<AdminCategories/>}/>
                        {/* Add other admin routes */}
                    </Routes>
                </Col>
            </Row>
        </Container>
    );
};

export default AdminRouter;
