import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, RootState} from "./store";
import CartItem from "../models/CartItem";
import Link from "../models/Link";
import Package from "../models/Package";
import {toast} from "react-toastify";
import {hideModal, Modals, showModal} from "./modalSlice";
import {ApiService} from "../services/api";
import {trimToHostname} from "./utils";
import AnalyticsService from "../services/AnalyticsService";
import {selectUser} from "./userSlice";
import {useSelector} from "react-redux";

interface CartState {
    items: CartItem[];
    paymentLink?: string;
}

const initialState: CartState = {
    items: [],
    paymentLink: undefined,
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<CartItem>) => {
            // Check if the item already exists in the cart
            const existingItem = state.items.find(item =>
                item.itemId === action.payload.itemId &&
                item.itemType === action.payload.itemType &&
                item.wordCount === action.payload.wordCount);

            if (existingItem) {
                // If item exists, increment its quantity
                existingItem.quantity += action.payload.quantity;
            } else {
                // If item does not exist, add it to the cart
                state.items.push(action.payload);
            }
        },
        removeFromCart: (state, action: PayloadAction<CartItem>) => {
            state.items = state.items.filter(item =>
                !(item.itemId === action.payload.itemId &&
                    item.wordCount === action.payload.wordCount &&
                    item.usersContent === action.payload.usersContent));
        },
        reduceQuantityInCart: (state, action: PayloadAction<CartItem>) => {
            const item = state.items.find(i => i.itemId === action.payload.itemId);
            if (item) {
                item.quantity -= 1;
            }
        },
        increaseQuantityInCart: (state, action: PayloadAction<CartItem>) => {
            const item = state.items.find(i => i.itemId === action.payload.itemId);
            if (item) {
                item.quantity += 1;
            }
        },
        setCart: (state, action: PayloadAction<CartItem[]>) => {
            state.items = action.payload;
        },
        clearCartAction: (state) => {
            state.items = [];
        },
        setPaymentLink: (state, action: PayloadAction<string | undefined>) => {
            state.paymentLink = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(finishPurchase.fulfilled, (state, action) => {
            // Handle the successful purchase here, if needed
            if (action.payload) {
                state.paymentLink = action.payload
            }
        });
        builder.addCase(finishPurchase.rejected, (state, action) => {
            toast.error("שגיאה בעת מעבר לעמוד התשלום. אנא נסה שנית מאוחר יותר");
        });
        // Handle other cases like pending, rejected if necessary
    },
});

export const {
    addToCart,
    removeFromCart,
    reduceQuantityInCart,
    increaseQuantityInCart,
    clearCartAction,
    setCart,
    setPaymentLink
} = cartSlice.actions;
export const selectCart = (state: RootState) => state.cart.items;
export const selectPaymentLink = (state: RootState) => state.cart.paymentLink;


export const removeProductFromCart = (item: CartItem) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(removeFromCart(item));

    try {
        const analyticsService = AnalyticsService.getInstance();
        const newCart = await postCartToApi(dispatch, getState);
        let ids = newCart.data?.items?.map(item => `${item.itemType}_${item.itemId}`)
        if (ids?.length == 0) {
            ids = [""]
        }
        analyticsService.updateCart(ids, newCart.data.totalPrice, {
        })
    }catch (e) {
        console.error(e)
    }
};

export const clearCart = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(clearCartAction());

    try {
        const analyticsService = AnalyticsService.getInstance();
        const newCart = await postCartToApi(dispatch, getState);
        let ids = newCart.data?.items?.map(item => `${item.itemType}_${item.itemId}`)
        if (ids?.length == 0) {
            ids = [""]
        }
        analyticsService.updateCart(ids, newCart.data.totalPrice, {
        })
    }catch (e) {
        console.error(e)
    }
};

export const reduceQuantityOfProductInCart = (item: CartItem) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(reduceQuantityInCart(item));
    await postCartToApi(dispatch, getState);
};

export const increaseQuantityOfProductInCart = (item: CartItem) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(increaseQuantityInCart(item));
    await postCartToApi(dispatch, getState);
};

export const addProductToCart = (item: Link & {
    wordCount?: number
    usersContent?: boolean
} | Package, notify = true) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        let displayName: string;
        let itemType: 'package' | 'link'
        if ('linksCount' in item) {
            itemType = 'package';
            displayName = `${item.name}`;
        } else {
            itemType = 'link';
            displayName = trimToHostname(item['website']);
        }

        let wordCount;
        if ('wordCount' in item) {
            if (item.wordCount === 0) {
                wordCount = 500;
            } else {
                wordCount = item.wordCount;
            }
        }

        dispatch(addToCart({
            itemType: itemType!!,
            itemId: item.id,
            quantity: 1,
            displayName: displayName!!,
            price: item.price,
            thumbnailUrl: item.thumbnail,
            wordCount,
            usersContent: 'usersContent' in item ? item?.usersContent : false
        }));

        (window as any)?.dataLayer?.push({
            'event': 'cart-item-add',
            'value': item.price,
            'currency': 'ILS'
        });

        try {
            const analyticsService = AnalyticsService.getInstance();
            const newCart = await postCartToApi(dispatch, getState);
            const ids = newCart.data?.items?.map(item => `${item.itemType}_${item.itemId}`)
            analyticsService.updateCart(ids, newCart.data.totalPrice, {
                type: itemType,
                packageSize: 'linksCount' in item ? item.linksCount : undefined,
                price: item.price,
            })
        }catch (e) {
            console.error(e)
        }

        if (notify) {
            toast.success(`הפריט נוסף לסל בהצלחה`);
            dispatch(showModal(Modals.CART))
        }
    } catch (error) {
        // Handle the error appropriately
        console.error(error);

        toast.error(`שגיאה בעת הוספה לסל`);
    }
};

async function postCartToApi(dispatch: AppDispatch, getState: () => RootState) {
    const state = getState();
    const apiService = ApiService.getInstance(dispatch);

    const cartBody = {
        items: state.cart.items.map(item => {
            const {price, thumbnailUrl, ...rest} = item; // destructure out the price property and collect the rest into a new object
            return rest;
        })
    };

    return await apiService.api.post<{items: CartItem[], totalPrice: number}>('/cart', cartBody);
}

export const finishPurchase = createAsyncThunk(
    'cart/finishPurchase',
    async (_, thunkAPI) => {
        const apiService = ApiService.getInstance(thunkAPI.dispatch as AppDispatch);

        thunkAPI.dispatch(showModal(Modals.MOVING_TO_PAYMENT))

        const state = thunkAPI.getState() as RootState
        const items = state.cart.items

        const sum = state.cart.items.reduce((total, item) => total + item.price, 0);
        (window as any)?.dataLayer?.push({
            'event': 'cart-checkout-start',
            'value': sum,
            'currency': 'ILS'
        });

        const response = await apiService.api.post('/cart/checkout', {items});
        return response.data.paymentUrl;
    }
);

export default cartSlice.reducer;