import React, {useEffect} from 'react';
import {Provider, useDispatch} from 'react-redux';
import MainRouter from "./router/MainRouter";
import {createTheme, ThemeProvider} from "@mui/material";
import {checkLoginStatus} from "./utils/userSlice";
import {ApiServiceProvider} from './context/ApiServiceContext';
import {AnalyticsServiceProvider} from "./context/AnalyticsServiceContext";

// import LinkList from './components/LinkList';

const theme = createTheme({
    direction: 'rtl',
    palette: {
        primary: {
            main: '#fff',
        },
        secondary: {
            main: "#251FB6",
        },
    },
    typography: (theme) => ({
        fontFamily: "'Assistant', sans-serif",
        h2: {
            fontWeight: 700,
            fontSize: "48px",
            lineHeight: "1.2"
        },
        h3: {
            fontWeight: 700,
            fontSize: "42px",
            lineHeight: "1.2"
        },
        h4: {
            fontWeight: 800,
            fontSize: "32px",
            lineHeight: "1.5"
        },
        h5: {
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "1.5"
        },
        subtitle2: {
            fontSize: "12px",
        },
    }),
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    textAlign: 'right', // Align text to the right for all list items
                },
            }
        },
    },
});

theme.typography.h1 = {
    ...theme.typography.h1,
    fontWeight: 700,
    lineHeight: "1.2",
    [theme.breakpoints.down('sm')]: {
        fontSize: '40px', // Smaller font size for small screens
    },
    [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '60px', // Medium font size for medium screens
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '80px', // Larger font size for large screens
    },
};
// Augmenting h2
theme.typography.h2 = {
    ...theme.typography.h2,
    fontWeight: 700,
    lineHeight: "1.2",
    [theme.breakpoints.down('sm')]: {
        fontSize: '36px', // Smaller font size for small screens
    },
    [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '40px', // Medium font size for medium screens
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '48px', // Original font size for large screens
    },
};

// Augmenting h3
theme.typography.h3 = {
    ...theme.typography.h3,
    fontWeight: 700,
    lineHeight: "1.2",
    [theme.breakpoints.down('sm')]: {
        fontSize: '32px', // Smaller font size for small screens
    },
    [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '36px', // Medium font size for medium screens
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '42px', // Original font size for large screens
    },
};

// Augmenting h4
theme.typography.h4 = {
    ...theme.typography.h4,
    fontWeight: 800,
    lineHeight: "1.5",
    [theme.breakpoints.down('sm')]: {
        fontSize: '24px', // Smaller font size for small screens
    },
    [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '32px', // Medium font size for medium screens
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '32px', // Original font size for large screens
    },
};

// Augmenting h5
theme.typography.h5 = {
    ...theme.typography.h5,
    fontWeight: 700,
    lineHeight: "1.5",
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px', // Smaller font size for small screens
    },
    [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '20px', // Medium font size for medium screens
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '24px', // Original font size for large screens
    },
};

const App: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkLoginStatus());
    }, [dispatch]);

    return (
        <ThemeProvider theme={theme}>
            <AnalyticsServiceProvider>
                <ApiServiceProvider>
                    <MainRouter/>
                </ApiServiceProvider>
            </AnalyticsServiceProvider>
        </ThemeProvider>
    )
}

export default App;
