import React, {useEffect, useState} from 'react';
import {Card, Col, Modal, Row} from "react-bootstrap";
import {addProductToCart, clearCartAction, finishPurchase} from "../../../utils/cartSlice";
import {AppDispatch} from "../../../utils/store";
import {useDispatch, useSelector} from "react-redux";
import Package from "../../../models/Package";
import {Box, Button, Typography} from "@mui/material";
import {ShoppingCart as ShoppingCartIcon} from "@material-ui/icons";
import {makeStyles} from '@material-ui/core';
import {hideModal, Modals, showModal} from "../../../utils/modalSlice";
import {selectIsLoggedIn, selectUser} from "../../../utils/userSlice";
import {styled} from "@mui/material/styles";
import {prettifyCurrency} from "../../../utils/utils";
import AnalyticsService from "../../../services/AnalyticsService";

const useStyles = makeStyles((theme) => ({
    buttonWithIcon: {
        '& .MuiButton-startIcon': {
            marginRight: '-6px',
            marginLeft: '2px',
        },
        color: 'black'
    },
}));

const StyledButton = styled(Button)(({theme}) => ({
    width: '140px',
    [theme.breakpoints.between('md', 'lg')]: {
        width: '150px',
        fontSize: '16px !important'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '120px!important',
    },
}));

const StyledModal = styled(Modal)(({theme}) => ({
    "& .modal-dialog": {
        "--bs-modal-width": '700px'
    },
}));

const StyledCard = styled(Card)(({theme}) => ({
    width: "100%",
    height: "266px",
    borderRadius: "10px",
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
    },
}));

const PromoPackageComponent: React.FC<Package> = (pack) => {
    const classes = useStyles();
    const dispatch: AppDispatch = useDispatch();
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const analyticsService = AnalyticsService.getInstance();

    const isLoggedIn = useSelector(selectIsLoggedIn);

    useEffect(() => {
        if (showDetailsModal) {

            analyticsService.sendViewContentEvent(`package_${pack.id}`, {
                type: 'package',
                packageSize: pack.linksCount
            })
        }
    }, [showDetailsModal])

    const handleAddProductToCart = () => {
        if (isLoggedIn) {
            dispatch(addProductToCart({...pack}))
        } else {
            dispatch(showModal(Modals.LOGIN))
        }
    }

    const handleBuyNow = async () => {
        if (isLoggedIn) {
            try {
                dispatch(clearCartAction())
                await dispatch(addProductToCart({...pack}, false))
                dispatch(finishPurchase())
            } catch (e) {
                dispatch(hideModal(Modals.MOVING_TO_PAYMENT))
            } finally {
                setShowDetailsModal(false)
            }
        } else {
            dispatch(showModal(Modals.LOGIN))
        }
    }

    return (
        <>
            <StyledCard key={pack.id}>
                <Card.Body className={"d-flex flex-column justify-content-between h-100"}
                           style={{padding: "24px 16px"}}>
                    <Row>
                        <Col xs={4} className="d-flex align-items-center">
                            <img className="rounded-4" style={{width: "100%", aspectRatio: 1}} src={pack.thumbnail}/>
                        </Col>
                        <Col xs={8}>
                            {pack.info.oldPrice && (pack.info?.discountPercentage ?? 0) > 0 &&
                                <div className="position-absolute" style={{
                                    top: "-30px",
                                    left: 0,
                                    fontSize: "32px",
                                    fontWeight: 700,
                                    height: "60px",
                                    padding: "0 20px",
                                    borderRadius: "8px",
                                    backgroundColor: "#251FB6",
                                    color: "white",
                                    lineHeight: "58px"
                                }}>
                                    {pack.info.discountPercentage}%
                                </div>}
                            <Typography variant="h6" style={{fontWeight: 700}}
                                        className="mb-2">{`${pack.name}`}</Typography>
                            <div className="overflow-y-auto" style={{height: "72px"}}>
                                <Typography variant="body1">
                                    {pack.info?.description}
                                </Typography>
                            </div>
                            <Box className="mb-2">
                                <Typography variant="h4"
                                            style={{
                                                marginLeft: "5px",
                                                display: "inline-block",
                                                color: "#251FB6",
                                                fontWeight: 700
                                            }}>{prettifyCurrency(pack.price)}₪</Typography>
                                {pack.info.oldPrice &&
                                    <Typography variant="h5" style={{
                                        display: "inline-block",
                                        color: "rgba(48, 48, 48, 0.50)",
                                        fontWeight: 300,
                                        textDecoration: "line-through"
                                    }}>{prettifyCurrency(pack.info.oldPrice)}₪</Typography>}
                            </Box>

                            <StyledButton color="secondary" variant={"contained"}
                                          className={`simple-button medium blue mb-2`}
                                          style={{marginLeft: "10px"}}
                                          onClick={handleAddProductToCart}>
                                הוסף לעגלה
                            </StyledButton>
                            <StyledButton color="primary" variant={"outlined"}
                                          className={`${classes.buttonWithIcon} simple-button medium white mb-2`}
                                          onClick={() => setShowDetailsModal(true)}>
                                פרטים נוספים
                            </StyledButton>
                        </Col>
                    </Row>
                </Card.Body>
            </StyledCard>

            <StyledModal size="lg" show={showDetailsModal} onHide={() => setShowDetailsModal(false)} centered>
                <Modal.Body>
                    <Row>
                        <Col xs={4} className="d-flex align-items-center mt-2">
                            <img className="rounded-4" style={{width: "100%", aspectRatio: 1}} src={pack.thumbnail}/>
                        </Col>
                        <Col xs={8} className="mt-4">
                            <Typography variant="h6" style={{fontWeight: 700}}
                                        className="mb-2">{`${pack.name}`}</Typography>
                            <div>
                                <Typography variant="body1">
                                    {pack.info?.description}
                                </Typography>
                            </div>
                            {(pack.info.bullets?.length ?? 0) > 0 && <ul>
                                {pack.info.bullets?.map((b, index) =>
                                    <li key={`bullet-${index}`}>{b}</li>
                                )}
                            </ul>}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Box className="d-inline-block">
                                <Typography variant="h4"
                                            style={{
                                                marginLeft: "5px",
                                                display: "inline-block",
                                                color: "#251FB6",
                                                fontWeight: 700,
                                                lineHeight: 1
                                            }}>{prettifyCurrency(pack.price)}₪</Typography>
                                {pack.info.oldPrice &&
                                    <Typography variant="h5" style={{
                                        display: "inline-block",
                                        color: "rgba(48, 48, 48, 0.50)",
                                        fontWeight: 300,
                                        textDecoration: "line-through"
                                    }}>{prettifyCurrency(pack.info.oldPrice)}₪</Typography>}
                            </Box>

                            <Box className="d-inline-block float-end">
                                <StyledButton color="secondary" variant={"contained"}
                                              className={`simple-button medium blue`}
                                              style={{marginLeft: "10px"}}
                                              onClick={handleBuyNow}>
                                    קנה עכשיו
                                </StyledButton>
                                <StyledButton color="primary" variant={"outlined"}
                                              className={`${classes.buttonWithIcon} simple-button medium white`}
                                              onClick={handleAddProductToCart}>
                                    הוסף לעגלה
                                </StyledButton>
                            </Box>
                        </Col>
                    </Row>
                </Modal.Body>
            </StyledModal>
        </>
    );
};

export default PromoPackageComponent;