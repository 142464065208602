import React from 'react';
import {AppDispatch} from "../../utils/store";
import {useDispatch} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import {Button, Typography} from "@mui/material";
import linkngoMain from "../../assets/images/linkngo-main.png";
import byRego from "../../assets/images/rego-logo.png";
import {useNavigate} from "react-router-dom";
import {makeStyles} from '@material-ui/core';
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        [theme.breakpoints.down('sm')]: {
            height: '60px', // Smaller screen size (small)
        },
        [theme.breakpoints.between('sm', 'md')]: {
            height: '80px', // Medium screen size (medium)
        },
        [theme.breakpoints.up('lg')]: {
            height: 'auto', // Larger screen size (large)
        },
    }
}));


interface MainHeroProps {
    videoId: string;
}

const MainHero: React.FC<MainHeroProps> = ({videoId}) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const classes = useStyles();

    function handleBuyLinkClick() {
        navigate('/boost', {replace: true});
    }

    const scrollToVideo = () => {
        const appBarHeight = 100; // Height of AppBar (92, I added 8)
        const videoElement = document.getElementById(videoId);
        if (videoElement) {
            const yOffset = -appBarHeight; // Negative offset to account for the AppBar
            const y = videoElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return (
        <>
            <Container className="mb-4 text-center" style={{marginTop:"150px", height: "auto",}}>
                <Row className="justify-content-center">
                    <Col sm={12} className="mb-2">
                        <img src={linkngoMain} style={{maxWidth: "90%"}}/>
                    </Col>
                    <Col sm={"auto"} className="pe-md-0" dir="ltr">
                        <span style={{fontSize:"30px", fontWeight:300, marginRight:"10px"}}>By</span><img src={byRego} className={classes.logo} alt="Subtitle imagery" style={{paddingLeft: 0}}/>
                    </Col>
                </Row>
                <Row sm={12} className="justify-content-center">
                    <Col xl={8}>
                        <Typography variant="h4" style={{marginTop: "20px", fontWeight: 400}}>
                            רכישת קישורים אונליין להגדלת התנועה האורגנית מגוגל.
                        </Typography>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center">
                    <Button color="secondary" variant={"contained"}
                            className='simple-button long blue'
                            style={{marginLeft: "10px"}}
                            onClick={handleBuyLinkClick}>
                        לקניית קישור
                    </Button>
                    <Button color="primary" variant={"outlined"}
                            className="simple-button long white" onClick={scrollToVideo}>
                        לסרטון הדרכה
                    </Button>
                </Row>
            </Container>
        </>
    );
};

export default MainHero;