import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {AxiosResponse} from "axios";
import {useApiService} from "../../context/ApiServiceContext";
import Link from "../../models/Link";
import * as yup from 'yup';
import {useFormik} from "formik";
import {CircularProgress} from "@mui/material";
import Category from "../../models/Category";
import {styled} from "@mui/system";

export interface LinkRequestBody {
    id?: string;
    website: string;
    categoryId: string;
    subCategoryIds: string[];
    price: number;
    info: {
        title?: string;
        description?: string;
        bullets?: string[];
    };
    thumbnail?: string;
    mainImg?: string;
    siteType?: string;
    metadata: {
        traffic: number;
        rd: number;
        dr: number;
    };
    recommended?: boolean;
    exampleSite?: boolean;
    exampleArticleUrl?: string;
}

const FormGrid = styled('div')({
    display: 'flex',
    flexWrap: "wrap",
    "& > div": {
        flex: "0 0 50%", /* Each form group takes up 50% width */
        padding: "5px" /* Optional spacing */
    },
    "& > div.col-3": {
        flex: "0 0 33.33333%", /* Each form group takes up 50% width */
    },
    "& > div.col-1": {
        flex: "0 0 100%", /* Each form group takes up 50% width */
    }
});

const AdminLinks: React.FC = () => {
    const apiService = useApiService();
    const [categories, setCategories] = useState<Category[]>([]);
    const [links, setLinks] = useState<Link[]>([]);
    const [selectedLink, setSelectedLink] = useState<Link | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [linkFields, setLinkFields] = useState<LinkRequestBody>({
        website: '',
        categoryId: '',
        subCategoryIds: [] as string[],
        price: 0,
        info: {
            title: '',
            description: '',
            bullets: ['', '', ''],
        },
        metadata: {traffic: 0, rd: 0, dr: 0},
        thumbnail: '',
        mainImg: '',
        siteType: '',
        exampleArticleUrl: ''
    });
    const [isFileUploading, setFileUploading] = useState<boolean>(false);
    const [searchWebsite, setSearchWebsite] = useState('');

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const linksPerPage = 20; // Adjust as needed
    /* End of Pagination */

    const [selectedSubCategories, setSelectedSubCategories] = useState<{ id: string, name: string }[]>([]);

    // Function to handle subcategory selection
    const handleSelectSubCategory = (categoryId: string) => {
        const category = categories.find(c => c.id === categoryId);
        if (category && !selectedSubCategories.find(sc => sc.id === categoryId)) {
            setSelectedSubCategories([...selectedSubCategories, {id: categoryId, name: category.name}]);
        }
    };

    // Function to remove a selected subcategory
    const removeSubCategory = (categoryId: string) => {
        setSelectedSubCategories(selectedSubCategories.filter(sc => sc.id !== categoryId));
    };

    useEffect(() => {
        formik.setFieldValue("subCategoryIds", selectedSubCategories.map(sc => sc.id));
    }, [selectedSubCategories]);


    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = () => {
        apiService.api.get('/category/list?per_page=50').then((res) => setCategories(res.data));
    };

    const fetchLinks = useCallback(async (page = 1, website: string) => {
        try {
            const url = `/link/list?page=${page}&per_page=${linksPerPage}${website.trim() != '' ? `&website=${website}` : ''}`
            const res = await apiService.api.get(url);
            setLinks(res.data);
            setHasMorePages(res.data.length === linksPerPage);
        } catch (error) {
            // Handle error
        }
    }, [apiService, linksPerPage]);

    useEffect(() => {
        fetchLinks(currentPage, searchWebsite);
    }, [currentPage, fetchLinks]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchWebsite(e.target.value);
    };

    const handleSearch = (e?: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        setLinks([])
        fetchLinks(1, searchWebsite);
    };

    const handleDelete = (linkId: string) => {
        if (window.confirm('האם אתה בטוח שברצונך למחוק את הקישור?')) {
            apiService.api
                .delete(`/link/${linkId}`)
                .then(() => {
                    setLinks(links.filter((link) => link.id !== linkId));
                    toast.success('הקישור נמחק בהצלחה');
                })
                .catch(() => {
                    toast.error('תקלה בעת מחיקת הקישור');
                });
        }
    };

    const handleUpdate = (values: any) => {
        if (selectedLink) {
            const updatedLink: LinkRequestBody = {
                // ...selectedLink,
                ...values
            };

            apiService.api
                .put(`/link/${selectedLink.id}`, updatedLink)
                .then((linkRes: AxiosResponse<Link>) => {
                    setLinks(
                        links.map((link) => (link.id === selectedLink.id ? linkRes.data : link))
                    );
                    toast.success('הקישור עודכן בהצלחה');
                })
                .catch(() => {
                    toast.error('תקלה בעת עדכון הקישור');
                });

            setShowModal(false);
            formik.resetForm()
        }
    };

    const handleCreate = (values: any) => {
        const newLink: LinkRequestBody = values;

        apiService.api
            .post('/link/', newLink)
            .then((linkRes: AxiosResponse<Link>) => {
                setLinks([linkRes.data, ...links]);
                toast.success('הקישור נוסף בהצלחה');
            })
            .catch(() => {
                toast.error('תקלה בעת הוספת הקישור');
            });

        setShowModal(false);
        formik.resetForm()

        setSelectedLink(null);
        setSelectedSubCategories([])
    };

    const openModal = (link: Link | null) => {
        setSelectedLink(link);
        setSelectedSubCategories(link?.subCategories ?? [])
        setLinkFields({
            website: link ? link.website : '',
            categoryId: link ? link.category.id : '',
            subCategoryIds: link ? link.subCategories.map(x => x.id) : [],
            price: link ? link.price : 0,
            info: {
                title: link ? link.info.title || '' : '',
                description: link ? link.info.description || '' : '',
                bullets: [...(link?.info.bullets || []), '', '', ''].slice(0, 3)
            },
            metadata: link ? link.metadata || {traffic: 0, rd: 0, dr: 0} : {traffic: 0, rd: 0, dr: 0},
            thumbnail: link ? link.thumbnail || '' : '',
            mainImg: link ? link.mainImg || '' : '',
            siteType: link ? link.siteType || '' : '',
            recommended: link ? link.recommended || false : false,
            exampleSite: link ? link.exampleSite || false : false
        });
        setShowModal(true);
    };

    const handleFileChange = (files: File[], target: 'thumbnail' | 'mainImg') => {
        if (files && files.length > 0) {
            setFileUploading(true)

            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`file${index}`, file);
            });

            apiService.api.post(`/link/thumbnail/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    formik.setFieldValue(target, response.data.urls[0]);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setFileUploading(false)
                });
        }
    };


    const PaginationBar = () => {
        return (
            <div className="d-flex justify-content-center my-3">
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="me-2"
                >
                    הקודם
                </Button>
                <Button
                    disabled={!hasMorePages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    הבא
                </Button>
            </div>
        );
    };

    const linkSchema = yup.object().shape({
        website: yup.string().required('כתובת נדרשת').url('כתובת אתר לא חוקית'),
        categoryId: yup.string().required('קטגוריה נדרשת'),
        price: yup.number().required('מחיר נדרש').min(0, 'המחיר לא יכול להיות שלילי'),
        info: yup.object({
            title: yup.string().required('כותרת נדרשת'),
            description: yup.string(),
            bullets: yup.array().of(yup.string()),
        }),
        metadata: yup.object({
            traffic: yup.number().required('תנועה נדרשת').min(0, 'התנועה לא יכולה להיות שלילית'),
            rd: yup.number().required('rd נדרש').min(0, 'rd לא יכול להיות שלילי'),
            dr: yup.number().required('dr נדרש').min(0, 'dr לא יכול להיות שלילי')
        }),
        thumbnail: yup.string().url('כתובת תמונה לא חוקית'),
        mainImg: yup.string().url('כתובת תמונה לא חוקית'),
        siteType: yup.string().required('סוג האתר נדרש'),
        exampleArticleUrl: yup.string().url('כתובת אתר לא חוקית'),
        recommended: yup.boolean(),
        exampleSite: yup.boolean(),
    });

    const formik = useFormik({
        initialValues: linkFields,
        validationSchema: linkSchema,
        onSubmit: values => {
            const filteredBullets = values.info?.bullets?.filter(bullet => bullet.trim() !== '');

            // Create a new object with the filtered bullets
            const valuesWithFilteredBullets = {
                ...values,
                info: {
                    ...values.info,
                    bullets: filteredBullets,
                },
            };

            if (selectedLink) {
                handleUpdate(valuesWithFilteredBullets);
            } else {
                handleCreate(valuesWithFilteredBullets);
            }
        },
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
    });

    return (
        <Row className="justify-content-center py-md-5">
            <Col md={4}>
                <h1 className="mb-2">ניהול קישורים</h1>
            </Col>
            <Col md={8} className="d-flex justify-content-end align-items-center">
                <Form onSubmit={handleSearch} className="me-4 w-50">
                    <InputGroup>
                        <Form.Control
                            placeholder="חפש לפי אתר"
                            onChange={handleSearchChange}
                            value={searchWebsite}
                        />
                        <Button variant="outline-secondary" onClick={handleSearch}>
                            חפש
                        </Button>
                    </InputGroup>
                </Form>
                <Button onClick={() => openModal(null)}>הוסף קישור חדש</Button>
            </Col>
            <Col md={12}>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>כתובת האתר</th>
                        <th>קטגוריה</th>
                        <th>מחיר</th>
                        {/*<th>אתר חזק</th>*/}
                        {/*<th>תיאור</th>*/}
                        <th>תנועה</th>
                        <th>rd</th>
                        <th>dr</th>
                        <th>תמונה</th>
                        <th>סוג האתר</th>
                        <th>מומלץ</th>
                        <th>דוגמא</th>
                        <th>נוצר בתאריך</th>
                        <th>עודכן בתאריך</th>
                        <th>פעולות</th>
                    </tr>
                    </thead>
                    <tbody>
                    {links.map((link, index) => (
                        <tr key={link.id}>
                            <td>{(currentPage - 1) * linksPerPage + index + 1}</td>
                            <td>{link.website}</td>
                            <td>{link.category.name}</td>
                            <td>{link.price}</td>
                            {/*<td>{link.powerSite ? 'כן' : 'לא'}</td>*/}
                            {/*<td>{link.info.description}</td>*/}
                            <td>{link.metadata?.traffic}</td>
                            <td>{link.metadata?.rd}</td>
                            <td>{link.metadata?.dr}</td>
                            <td>
                                {link.thumbnail && (
                                    <img src={link.thumbnail} alt={link.website} width={50}/>
                                )}
                            </td>
                            <td>{link.siteType}</td>
                            <td>{link.recommended ? '✔️' : ''}</td>
                            <td>{link.exampleSite ? '✔️' : ''}</td>
                            <td>{new Date(link.createdAt).toLocaleDateString()}</td>
                            <td>
                                {link.updatedAt &&
                                    new Date(link.updatedAt).toLocaleDateString()}
                            </td>
                            <td>
                                <Button className="me-2" onClick={() => openModal(link)}>
                                    עריכה
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(link.id)}>
                                    מחק
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <PaginationBar/>

            </Col>

            <Modal show={showModal} onHide={() => {
                setShowModal(false)
                formik.resetForm()
            }} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedLink ? 'עדכון' : 'הוספת'} קישור</Modal.Title>
                </Modal.Header>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <FormGrid>
                            <Form.Group>
                                <Form.Label>כתובת אתר</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="website"
                                    placeholder="הכנס כתובת"
                                    value={formik.values.website}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.website && !!formik.errors.website}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.website}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>קטגוריה</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="categoryId"
                                    value={formik.values.categoryId}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.categoryId && !!formik.errors.categoryId}
                                >
                                    <option value="">בחר קטגוריה</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.categoryId}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>תת-קטגוריות</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => handleSelectSubCategory(e.target.value)}
                                    defaultValue=""
                                >
                                    <option value="" disabled>בחר תת-קטגוריות</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            {/* Display Selected Subcategories */}
                            <ul>
                                {selectedSubCategories.map(sc => (
                                    <li key={sc.id} style={{
                                        display: "inline-block",
                                        marginTop: "13px",
                                        marginLeft: "6px",
                                    }}>
                                        {sc.name}
                                        <Button variant="danger" className="p-0 ms-1"
                                                style={{width: "20px", height: "20px", lineHeight: "12px"}}
                                                onClick={() => removeSubCategory(sc.id)}>X</Button>
                                    </li>
                                ))}
                            </ul>

                            <Form.Group>
                                <Form.Label>כותרת</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="info.title"
                                    placeholder="הכנס כותרת"
                                    value={formik.values.info.title}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.info?.title && !!formik.errors.info?.title}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.info?.title}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>מחיר</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="price"
                                    placeholder="הכנס מחיר"
                                    value={formik.values.price}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.price && !!formik.errors.price}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.price}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="col-1">
                                <Form.Label>תיאור</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    name="info.description"
                                    placeholder="הכנס תיאור"
                                    value={formik.values.info.description}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.info?.description && !!formik.errors.info?.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.info?.description}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="col-3">
                                <Form.Label>נקודה 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="info.bullets[0]"
                                    value={formik.values.info.bullets?.[0]}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>

                            <Form.Group className="col-3">
                                <Form.Label>נקודה 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="info.bullets[1]"
                                    value={formik.values.info.bullets?.[1]}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>

                            <Form.Group className="col-3">
                                <Form.Label>נקודה 3</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="info.bullets[2]"
                                    value={formik.values.info.bullets?.[2]}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>

                            <Form.Group className="col-3">
                                <Form.Label>תנועה אורגנית</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="metadata.traffic"
                                    placeholder="הכנס תנועה אורגנית"
                                    value={formik.values.metadata.traffic}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.metadata?.traffic && !!formik.errors.metadata?.traffic}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.metadata?.traffic}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="col-3">
                                <Form.Label>rd</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="metadata.rd"
                                    placeholder="הכנס rd"
                                    value={formik.values.metadata.rd}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.metadata?.rd && !!formik.errors.metadata?.rd}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.metadata?.rd}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="col-3">
                                <Form.Label>dr</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="metadata.dr"
                                    placeholder="הכנס dr"
                                    value={formik.values.metadata.dr}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.metadata?.dr && !!formik.errors.metadata?.dr}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.metadata?.dr}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>תמונה
                                    {formik.values.thumbnail && formik.values.thumbnail.trim() !== "" &&
                                        <>
                                            {" ("}
                                            <a href={formik.values.thumbnail} target="_blank">פתח בחלון חדש</a>
                                            {")"}
                                        </>
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="thumbnail"
                                    placeholder="הכנס תמונה ממוזערת"
                                    value={formik.values.thumbnail}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.thumbnail && !!formik.errors.thumbnail}
                                    disabled
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.thumbnail}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId={`file`}>
                                <Form.Label>
                                    <div>
                                        <span>העלאת קובץ לתמונה</span>
                                        <CircularProgress className="ms-3" color="secondary"
                                                          size={13}
                                                          hidden={!isFileUploading}/>
                                    </div>
                                </Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFileChange(e.target.files ? Array.from(e.target.files) : [], 'thumbnail')}
                                    accept=".png,.jpg,.jpeg,.webp"
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>תמונה לעמוד הראשי
                                    {formik.values.mainImg && formik.values.mainImg.trim() !== "" &&
                                        <>
                                            {" ("}
                                            <a href={formik.values.mainImg} target="_blank">פתח בחלון חדש</a>
                                            {")"}
                                        </>
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mainImg"
                                    placeholder="הכנס תמונה לעמוד הראשי"
                                    value={formik.values.mainImg}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.mainImg && !!formik.errors.mainImg}
                                    disabled
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.mainImg}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId={`file`}>
                                <Form.Label>
                                    <div>
                                        <span>העלאת קובץ לתמונה לעמוד הראשי</span>
                                        <CircularProgress className="ms-3" color="secondary"
                                                          size={13}
                                                          hidden={!isFileUploading}/>
                                    </div>
                                </Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFileChange(e.target.files ? Array.from(e.target.files) : [], 'mainImg')}
                                    accept=".png,.jpg,.jpeg,.webp"
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>סוג האתר</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="siteType"
                                    value={formik.values.siteType}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.siteType && !!formik.errors.siteType}
                                >
                                    <option value="">בחר סוג אתר</option>
                                    <option value="super">Super</option>
                                    <option value="boost">Boost</option>
                                    <option value="premium">Premium</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.siteType}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>מאמר לדוגמא
                                    {formik.values.exampleArticleUrl && formik.values.exampleArticleUrl.trim() !== "" &&
                                        <>
                                            {" ("}
                                            <a href={formik.values.exampleArticleUrl} target="_blank">פתח בחלון חדש</a>
                                            {")"}
                                        </>
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="exampleArticleUrl"
                                    placeholder="הכנס קישור למאמר לדוגמא"
                                    value={formik.values.exampleArticleUrl}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.exampleArticleUrl && !!formik.errors.exampleArticleUrl}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.exampleArticleUrl}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div>
                                <Form.Group className="form-check">
                                    <Form.Check
                                        type="checkbox"
                                        id="recommended"
                                        name="recommended"
                                        label="הצג במומלצים"
                                        checked={formik.values.recommended}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </Form.Group>

                                <Form.Group className="form-check">
                                    <Form.Check
                                        type="checkbox"
                                        id="exampleSite"
                                        name="exampleSite"
                                        label="הצג באתרים לדוגמא"
                                        checked={formik.values.exampleSite}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </Form.Group>
                            </div>
                        </FormGrid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            ביטול
                        </Button>
                        <Button variant="primary" type="submit">
                            שמירה
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Row>
    );
};

export default AdminLinks;
