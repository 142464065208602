import React from 'react';
import {NavLink} from "react-router-dom";
import {Container, Nav} from "react-bootstrap";

const AdminSidebar: React.FC = () => {
    return (
        <Container fluid className="d-flex vh-100 flex-column flex-shrink-0 p-3 text-bg-dark"
                   style={{ width: '16%', position: 'fixed', overflowY: 'auto' }}>
            <NavLink to="/admin/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <span className="fs-3">ניהול</span>
            </NavLink>
            <hr />
            <Nav className="flex-column mb-auto fs-5" variant="pills" defaultActiveKey="/home">
                <Nav.Item>
                    <Nav.Link as={NavLink} to="links" className="text-white">
                        לינקים
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="links-bulk-update" className="text-white">
                        עדכון לינקים מcsv
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="links-thumbnail-bulk-update" className="text-white">
                        עדכון תמונות ללינקים
                    </Nav.Link>
                </Nav.Item>
                <hr/>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="categories" className="text-white">
                        קטגוריות
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="recommended" className="text-white">
                        מומלצים
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="packages" className="text-white">
                        חבילות
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="promos" className="text-white">
                        מבצעים
                    </Nav.Link>
                </Nav.Item>
                <hr/>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="users" className="text-white">
                        משתמשים
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="orders" className="text-white">
                        הזמנות
                    </Nav.Link>
                </Nav.Item>
                <hr/>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="testimonials" className="text-white">
                        לקוחות מספרים
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="videos" className="text-white">
                        סרטונים
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="demo-articles" className="text-white">
                        מאמרים לדוגמא
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="faq" className="text-white">
                         שאלות תשובות
                    </Nav.Link>
                </Nav.Item>
                <hr/>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="legal" className="text-white">
                        מסמכים משפטיים
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="dynamic-pages" className="text-white">
                        עמודים דינמיים
                    </Nav.Link>
                </Nav.Item>
                <hr/>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="affiliation" className="text-white">
                         המלצות חמות
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <hr />
        </Container>
    );
};

export default AdminSidebar;